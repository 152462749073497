const faqData = [
  {
    faqHeading: 'General',
    headingUrl: '#general',
    open: true,
    headings: [
      {
        title: 'Why have I heard from Jacobs?',
        url: '#why-have-i-heard',
      },
      {
        title: 'What steps will Jacobs take to collect the debt?',
        url: '#what-steps',
      },
      {
        title:
          'What is the difference between an Enforcement Agent, Arrest Officer and Debt Collector?',
        url: '#what-is-the-difference',
      },
      {
        title: 'What fees do I have to pay?',
        url: '#what-fees',
      },
      {
        title: 'How long will I get to pay?',
        url: '#how-long',
      },
      {
        title: 'Where can I get free and impartial advice?',
        url: '#where-can-i',
      },
      {
        title: 'What do I need to do if I think I don’t owe the debt?',
        url: '#what-do-i-need',
      },
      {
        title:
          'What do I need to do if I have received a letter for somebody who doesn’t live at my address?',
        url: '#what-do-i-need-to-do-if',
      },
      {
        title: 'I forgot to make my agreed payment, what is going to happen?',
        url: '#i-forgot-to-make',
      },
      {
        title:
          'My circumstances have changed and I can’t afford the repayments I agreed to make?',
        url: '#my-circumstances',
      },
      {
        title:
          'I have received a hand delivered letter from an Enforcement Agent, what should I do?',
        url: '#from-an-enforcement',
      },
      {
        title:
          'I have received a hand delivered letter from an Arrest Officer, what should I do?',
        url: '#from-an-arrest-officer',
      },
      {
        title:
          'The Enforcement Agent is planning to remove my goods how can I avoid this?',
        url: '#remove-my-goods',
      },
      {
        title: 'How can I make a complaint about Jacobs?',
        url: '#make-a-complaint',
      },
      {
        title: 'Freeman on the Land',
        url: '#freeman-on-the-land',
      },
    ],
  },
  {
    faqHeading: 'Warrants of Control – Magistrates Court Fines',
    headingUrl: '#warrants-of-control',
    headings: [
      {
        title: 'What is the fine for I do not know what it is about?',
        url: '#what-is-the-fine-for',
      },
      {
        title: 'What does Forced Entry mean?',
        url: '#forced-entry-mean',
      },
      {
        title:
          'I have received a notice advising that you will apply to force entry into my property, how can I stop this?',
        url: '#received-a-notice',
      },
    ],
  },
  {
    faqHeading: 'Financial Arrest Warrants',
    headingUrl: '#financial-arrest',
    headings: [
      {
        title:
          'I have received notification that there is a warrant for my arrest, what shall I do?',
        url: '#warrant-for-my-arrest',
      },
      {
        title:
          'I cannot afford to pay in full immediately; can I agree to pay by instalments?',
        url: '#cannot-afford',
      },
      {
        title: 'I cannot pay, how can I avoid being arrested?',
        url: '#cannot-pay',
      },
      {
        title:
          'I cannot attend the court date I agreed to attend, what shall I do?',
        url: '#cannot-attend',
      },
      {
        title:
          'How can I stop being arrested where there is a commitment warrant/committal to custody order issued?',
        url: '#stop-being-arrested',
      },
      {
        title: 'Where can I be arrested?',
        url: '#where-can-i-be-arrested',
      },
      {
        title: 'What does ‘circulated to the police’ mean?',
        url: '#circulated-to-the-police',
      },
      {
        title: 'When can I be arrested?',
        url: '#be-arrested2',
      },
      {
        title: 'What does Forced Entry mean?',
        url: '#forced-entry2',
      },
    ],
  },
  {
    faqHeading: 'Community Penalty Breach Warrants',
    headingUrl: '#community',
    headings: [
      {
        title: 'I do not know what this is about',
        url: '#i-do-not-know',
      },
      {
        title: 'How can I avoid arrest?',
        url: '#how-can-i-avoid',
      },
      {
        title: 'I cannot attend the court date I agreed, what should I do?',
        url: '#i-cannot-attend-the-court',
      },
      {
        title: 'Where can I be arrested?',
        url: '#where-can-i-be-arrested2',
      },
      {
        title: 'What happens if I refuse to agree a date to attend court?',
        url: '#what-happens-if-i-refuse',
      },
      {
        title: 'What does ‘circulated to the police’ mean?',
        url: '#circulated-to-the-police2',
      },
      {
        title: 'When can I be arrested?',
        url: '#when-can-i-be-arrested',
      },
      {
        title: 'What does Forced Entry mean?',
        url: '#forced-entry-meaning',
      },
    ],
  },
  {
    faqHeading: 'Commitment Warrants',
    headingUrl: '#commitment',
    headings: [
      {
        title: 'What is a Commitment Warrant?',
        url: '#what-is-a-commitment-warrant',
      },
      {
        title:
          'How can I stop being arrested where there is a commitment warrant issued?',
        url: '#stop-being-arrested2',
      },
      {
        title: 'Where can I be arrested?',
        url: '#where-can-i-be',
      },
      {
        title: 'What does Forced Entry mean?',
        url: '#forced-entry',
      },
      {
        title: 'What does ‘circulated to the police’ mean?',
        url: '#circulated-to-the-police3',
      },
    ],
  },
  {
    faqHeading: 'Committal to Custody Overnight Warrants',
    headingUrl: '#committal',
    headings: [
      {
        title: 'What is a Committal to Custody Overnight Warrant?',
        url: '#what-is-committal',
      },
      {
        title:
          'How can I stop being arrested where there is a committal to custody order issued?',
        url: '#committal-to-custody',
      },
      {
        title: 'Where can I be arrested?',
        url: '#committal-where-can-i-be-arrested',
      },
      {
        title: 'What does Forced Entry mean?',
        url: '#committal-forced-entry',
      },
      {
        title: 'What does ‘circulated to the police’ mean?',
        url: '#committal-circulated',
      },
    ],
  },
];

export default faqData;
