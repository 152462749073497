import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Section from '../components/Section';
import defaultTheme from '../utils/themes';
import { Heading1, Heading3, Paragraph } from '../components/Typography';
import HeroSection from '../components/HeroSection';
import Table from '../components/Table';
import SEO from '../components/seo';
import FAQNavSidebar from '../components/FAQNavSidebar/';
import BulletPoint from '../components/BulletPoint';
import complainsHandlingPolicyPdf from '../files/complaints-handling-policy-v1.4 - 250523.pdf';
import ScrollToTop from '../components/ScrollToTop';
import FaqMobileNav from '../components/FAQNavSidebar/FaqMobileNav';
import Accordion from '../components/Accordion';
import Link from '../components/Link';

const FAQSection = styled.div`
	margin-bottom: 40px;
	background: #fff;
	padding: 24px;
	border-radius: 4px;
`;

const alignLeftStyle = css`
	text-align: left;
	font-size: 15px;
`;

const Flexbox = styled.div`
	display: flex;
	.flex-child :first-child {
		max-width: 345px;
	}
	.flex-child {
		margin-left: 40px;
		flex: 0.6;
		@media (max-width: 800px) {
			flex: 1;
			margin-left: 0px;
		}
	}
	.FaqNav {
		@media (max-width: 800px) {
			display: none;
		}
	}
`;

const MobileNavContainer = styled.div`
	display: none;
	margin-top: -60px;
	margin-bottom: 40px;
	@media (max-width: 800px) {
		display: block;
	}
`;

const FaqPage = () => (
  <>
    <SEO
      title='Frequently Asked Questions'
      description={`
                Here is a list of our most frequently asked questions by our customers that we hope you find.
            `}
    />
    <HeroSection paddingTop='8px'>
      <Heading1 marginTop='120px'>FAQ</Heading1>

      <Paragraph>
        Here are some frequently asked questions and answers you may find
        useful.
      </Paragraph>
    </HeroSection>
    <Section backgroundColor='grey' paddingTop='100px' paddingBottom='215px'>
      <MobileNavContainer>
        <FaqMobileNav />
      </MobileNavContainer>
      <Flexbox>
        <div className='flex-child FaqNav'>
          <FAQNavSidebar />
        </div>
        <div className='flex-child'>
          <Heading1 id='general' color='#3d3d3d'>
            General
          </Heading1>
          <Accordion
            title='Why have I heard from Jacobs?'
            id='why-have-i-heard'
          >
            <FAQSection>
              <Paragraph fontSize='16px' id='why-have-i-heard'>
                We receive our instruction from Councils to collect unpaid
                Council Tax, Business Rates, Parking Fines, Commercial Rent
                arrears, Former Tenant arrears, Housing Benefit Overpayments,
                Sundry Debts (and associated High Court enforcement). We receive
                our instruction from His Majesty’s Courts and Tribunals Service
                (HMCTS), for enforcement services in relation to the collection
                of financial impositions and Community Penalty Breach Warrants.
                The Court fines we enforce are:
              </Paragraph>
              <ul>
                <BulletPoint>
                  Warrants of Control (for unpaid financial impositions)
                </BulletPoint>
                <BulletPoint>
                  Arrest Warrants (Bail, No Bail, Breach and Committal)
                </BulletPoint>
                <BulletPoint>Compensation and Confiscation Orders</BulletPoint>
              </ul>
              <Paragraph fontSize='16px'>
                You have been issued a number of reminders and other notices,
                along with opportunities for challenges, before the debt
                progressed to the point of being sent to us.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='What steps will Jacobs take to collect the debt?'
            id='what-steps'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                Most debts we collect will enter the Compliance stage whereby we
                will make several attempts to contact you by letter and where
                possible phone or SMS, to negotiate the repayment of your debt.
                A charge of £75.00 will be incurred when the first Notice of
                Enforcement is issued to you.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If we are unsuccessful the debt will escalate to Enforcement
                stage during which an Enforcement Agent will visit your property
                to collect full payment or to assess your valuables for removal
                at auction. A fee of at least £235.00 will be charged on their
                first visit.
              </Paragraph>
              <Paragraph fontSize='16px'>
                Your debt could reach Sale stage if you do not cooperate with us
                and agree payment. This is where we will arrange to remove
                valuables (vehicle and/or household goods) for sale at auction
                to raise the funds to clear your debt. This stage costs an
                additional minimum of £110.00.
              </Paragraph>
              <Paragraph fontSize='16px'>
                The best thing to do is to{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  contact us
                </Link>
                , immediately, so that we can discuss the matter and try to
                reach a mutually beneficial arrangement. It’s important that you
                contact us to prevent further action taking place and additional
                fees being added to your debt.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='What is the difference between an Enforcement Agent, Arrest
                  Officer and Debt Collector?'
            id='what-is-the-difference'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                An Enforcement Agent will visit in order to execute Liability
                Orders/Warrants of Control for unpaid Council Tax, Business
                Rates, Parking & Bus Lane PCN and Magistrates Court fines.
              </Paragraph>
              <Paragraph fontSize='16px'>
                An Arrest Officer will visit in order to execute Arrest Warrants
                with Bail and Without Bail, Breach and Committal, Community
                Penalty Breach Warrants and execute Compensation and
                Confiscation orders issued by the court.
              </Paragraph>
              <Paragraph fontSize='16px'>
                A Debt Collector will visit in order to collect unpaid Sundry
                Debts, Housing Benefit Overpayments and Former Tenant arrears.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion title='What fees do I have to pay?' id='what-fees'>
            <FAQSection>
              <Table
                header={useMemo(
                  () => [
                    { content: 'Stage' },
                    { content: 'Fee', css: alignLeftStyle },
                    {
                      content: 'Note',
                      css: alignLeftStyle,
                    },
                  ],
                  []
                )}
                rows={useMemo(
                  () => [
                    [
                      { content: 'Compliance' },
                      {
                        content: '£75.00',
                        css: alignLeftStyle,
                      },
                      {
                        content:
                          'Charged once the Notice of Enforcement is issued to you. This is charged per debt.',
                        css: alignLeftStyle,
                      },
                    ],
                    [
                      { content: 'Enforcement' },
                      {
                        content: '£235.00',
                        css: alignLeftStyle,
                      },
                      {
                        content:
                          'Charged once an Enforcement Agent visits you. If your debt is over £1,500 the fee will be higher as an additional 7.5% is charged of the difference over £1,500. For example if your debt is £2,000 the fee would be £235.00 + 7.5% of £500 = £272.50, which is then rounded up to the nearest pound, therefore £273.00.',
                        css: alignLeftStyle,
                      },
                    ],
                    [
                      { content: 'Sale' },
                      {
                        content: '£110.00',
                        css: alignLeftStyle,
                      },
                      {
                        content:
                          'Charged once we have taken control of your goods and attended to remove them. If your debt is over £1,500 the fee will be higher as an additional 7.5% is charged of the difference over £1,500. For example if your debt is £2,000 the fee would be £110.00 + 7.5% of £500 = £147.50, which is then rounded up to the nearest pound, therefore £148.00.',
                        css: alignLeftStyle,
                      },
                    ],
                  ],
                  []
                )}
              />
              <Paragraph fontSize='16px'>
                <strong>Note:</strong> These fees are chargeable in respect of
                Liability orders for unpaid Council Tax and Business Rates,
                Warrants for unpaid Parking/Bus Lane Penalty Charge Notices and
                Warrants of Control for unpaid Court fines.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion title='How long will I get to pay?' id='how-long'>
            <FAQSection>
              <Paragraph fontSize='16px'>
                If you are unable to pay the debt in full, we may be able to
                offer you an arrangement to pay by instalments. This will depend
                on our Clients’ guidelines and your income, so you will need to
                provide us with some details about your employment along with
                evidence such as recent payslips or bank statements.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If you are in receipt of benefits, we will require proof of your
                entitlement in order for you to potentially qualify for a
                reduced arrangement.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='Where can I get free and impartial advice?'
            id='where-can-i'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                Jacobs work alongside a number of local and national
                organisations who offer free and impartial debt advice.
              </Paragraph>
              <Paragraph fontSize='16px'>
                Take our
                <Link
                  href='/stepchange-debt-test'
                  as='a'
                  target='__blank'
                  fontSize='16px'
                >
                  {' '}
                  60-second debt test.
                </Link>
              </Paragraph>
              <Paragraph fontSize='16px'>
                Here are some organisations you can contact for help:
              </Paragraph>
              <Heading3 marginBottom='0px'>Citizens Advice</Heading3>
              <Paragraph marginBottom='0px'>
                <Link
                  href='tel: 0800 144 8848'
                  aria-label='This is an external link (opens in a new tab)'
                >
                  0800 144 8848 – (Adviceline (England))
                </Link>
              </Paragraph>
              <Paragraph marginBottom='0px'>
                <Link
                  href='tel: 0800 702 2020 '
                  aria-label='This is an external link (opens in a new tab)'
                >
                  0800 702 2020 – (Adviceline (Wales))
                </Link>
              </Paragraph>
              <Paragraph>
                <Link
                  href='https://www.citizensadvice.org.uk/'
                  aria-label='This is an external link (opens in a new tab)'
                >
                  www.citizensadvice.org.uk
                </Link>
              </Paragraph>
              <Heading3 marginBottom='0px'>National Debtline</Heading3>
              <Paragraph>
                <Paragraph marginBottom='0px'>
                  <Link href='tel:0808 808 4000'>0808 808 4000</Link>
                </Paragraph>
                <Link
                  href='http://www.nationaldebtline.org'
                  aria-label='This is an external link (opens in a new tab)'
                >
                  www.nationaldebtline.org
                </Link>
              </Paragraph>
              <Heading3 marginBottom='0px'>Money Helper</Heading3>
              <Paragraph>
                <Paragraph marginBottom='0px'>
                  <Link href='tel:0800 138 7777'>0800 138 7777</Link>
                </Paragraph>
                <Link
                  href='https://www.moneyhelper.org.uk/'
                  aria-label='This is an external link (opens in a new tab)'
                >
                  www.moneyhelper.org.uk
                </Link>
              </Paragraph>
              <Heading3 marginBottom='0px'>Step Change Debt Charity</Heading3>
              <Paragraph marginBottom='0px'>
                <Link
                  href='tel: 0800 138 111'
                  aria-label='This is an external link (opens in a new tab)'
                >
                  0800 138 111
                </Link>
              </Paragraph>
              <Paragraph>
                <Link
                  href='https://www.stepchange.org/'
                  aria-label='This is an external link (opens in a new tab)'
                >
                  www.stepchange.org
                </Link>
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='What do I need to do if I think I don’t owe the debt?'
            id='what-do-i-need'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  Contact us
                </Link>{' '}
                right away and explain to us why you don’t think you owe the
                money. Do not ignore the situation in the hope it will go away.
              </Paragraph>
              <Paragraph fontSize='16px'>
                We can contact our client on your behalf to get answers to any
                queries you have and to verify that the debt is correct. Please
                be aware that you may still be liable for any fees already
                incurred by us.
              </Paragraph>
              <Paragraph fontSize='16px'>
                In respect of Court fines (Warrants of Control) if you believe
                that this Warrant has been issued incorrectly and you do not owe
                the debt outstanding you must contact the court directly to
                discuss this. Please note that we will continue to enforce the
                Warrant until such time the court withdraws the Warrant or
                payment in full is made.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='What do I need to do if I have received a letter for somebody who
                  doesn’t live at my address?'
            id='what-do-i-need-to-do-if'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  Contact us
                </Link>{' '}
                right away or telephone the Enforcement Agent, Arrest Officer or
                Debt Collector directly if you have received a hand delivered
                letter. We will need you to supply proof of your residency e.g.
                a recent utility bill, in order for our records to be updated
                and for our tracing process to begin.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If you know the debtor we would ask that you provide us with
                their forwarding address or that you ask him/her to contact us
                directly.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='I forgot to make my agreed payment, what is going to happen?'
            id='i-forgot-to-make'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                You need to{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  call us
                </Link>{' '}
                right away so that we can review your case and see what the next
                steps are. It may be that we can reinstate your arrangement once
                you have paid the arrears, but we will inform you of the next
                steps when you call.
              </Paragraph>
              <Paragraph fontSize='16px'>
                The worst thing you can do is ignore us as this could result in
                your debt escalating and further charges being incurred.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title=' My circumstances have changed and I can’t afford the repayments I
                  agreed to make'
            id='my-circumstances'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  Get in touch
                </Link>{' '}
                with us so that we can update your details and see whether we
                can renegotiate your payments. We will need to complete a
                Financial Statement with you and we may need to ask for proof of
                your income.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='I have received a hand delivered letter from an Enforcement Agent,
                  what should I do?'
            id='from-an-enforcement'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                Call them right away on the mobile number provided on the
                letter.
              </Paragraph>
              <Paragraph fontSize='16px'>
                By the time a case gets to our Enforcement Agent their role is
                to collect the full amount or to enforce the debt by removing
                goods which will then be sold at auction. They may be able to
                allow you a short period of time to raise the funds but will
                only do this if you speak to them and the Enforcement Agent
                agrees that based on your circumstances, this is acceptable. It
                is always best to contact them because if they do not hear from
                you, they will proceed with organising the removal so it is
                always best to contact them ASAP.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='I have received a hand delivered letter from an Arrest Officer,
                  what should I do?'
            id='from-an-arrest-officer'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                Call them right away on the mobile number on the letter.
              </Paragraph>
              <Paragraph fontSize='16px'>
                An Arrest Officer’s job is to collect the full amount owed or to
                execute the Arrest Warrant. If it is in respect of a Financial
                Arrest Warrant they may be able to allow you a short time to
                raise the funds otherwise it is best arranging a convenient time
                for the Officer to revisit you to discuss your options.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='The Enforcement Agent is planning to remove my goods how can I
                  avoid this?'
            id='remove-my-goods'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                Removing goods is the last thing we want to do, but if your debt
                has reached removal / Sale stage it is because you have either
                not contacted us to arrange repayments or because you have
                defaulted on agreed payments.
              </Paragraph>
              <Paragraph fontSize='16px'>
                The Enforcement Agent in charge will always assess your
                circumstances to ensure there are no other suitable options
                before proceeding with a removal. Please speak to the Agent as a
                matter of urgency.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='How can I make a complaint about Jacobs?'
            id='make-a-complaint'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                We are committed to getting things right and any complaints
                received are treated very seriously.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If you would like to lodge a formal complaint you can do so by
                email to{' '}
                <Link
                  href='mailto:complaint@jacobsenforcement.com
    '
                >
                  complaint@jacobsenforcement.com
                </Link>{' '}
                or by post to Jacobs 6 Europa Boulevard, Birkenhead, Wirral CH41
                4PE.
              </Paragraph>
              <Paragraph fontSize='16px'>
                All complaints will be acknowledged within 2 days and a full
                response will be issued within 10 working days. Our response
                will also be shared with the client as we are acting on their
                behalf.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If you wish to see full details please refer to our{' '}
                <Link href={complainsHandlingPolicyPdf}>
                  Complaints Handling Policy
                </Link>
                .
              </Paragraph>
            </FAQSection>
          </Accordion>
          <Accordion title='Freeman on the Land' id='freeman-on-the-land'>
            <FAQSection>
              <Paragraph fontSize='16px'>
                You do not have a choice as to whether you are liable for
                council tax and being a ‘freeman’ does not exempt anyone from
                paying council tax.
              </Paragraph>
              <Paragraph fontSize='16px'>
                Your liability for council tax is not dependent on, and does not
                require your consent or the existence of a contractual
                relationship with the council. Any such assertion to the
                contrary is incorrect and there is no legal basis upon which to
                make this argument.
              </Paragraph>
              <Paragraph fontSize='16px'>
                Anyone who withholds payment will have recovery action taken
                against them.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If you have any concerns over the charging of council tax,
                please seek proper legal advice, rather than relying on internet
                sources or forum statements which may be incorrect or
                misleading.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Heading1 color='#3d3d3d' marginTop='40px'>
            Warrants of Control – Magistrates Court Fines
          </Heading1>

          <Accordion
            title='What is the fine for I do not know what it is about?
'
            id='what-is-the-fine-for'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                A Warrant has been issued following non-payment of a fine that
                was imposed on you by the criminal courts. Details of the court
                that issued the fine, the court reference and hearing date(s)
                are detailed on all of the letters Jacobs have sent to you.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If you believe that this Warrant has been issued incorrectly and
                you do not owe the debt outstanding you must contact the
                Magistrates court directly to discuss this. Please note that we
                will continue to enforce the Warrant until such time the court
                withdraws the Warrant or payment in full is made.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='What does Forced Entry mean?'
            id='forced-entry-mean'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                If you fail to pay or communicate with us in order to resolve
                this matter, we may apply under Paragraph 17 of Schedule 12 of
                the Tribunals, Courts and Enforcement Act 2007 to enter your
                property,
                <strong> BY FORCE</strong>, using locksmiths where required, to
                execute a Magistrates’ Court Warrant and seize goods to cover
                the balance outstanding.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='I have received a notice advising that you will apply to force
                  entry into my property, how can I stop this?'
            id='received-a-notice'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                Please{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  contact us
                </Link>{' '}
                immediately to avoid further costs and forced entry action.
              </Paragraph>
              <Paragraph fontSize='16px'>
                A Warrant of Control has been issued by His Majesty’s Courts and
                Tribunals Service (HMCTS) following non-payment of a criminal
                fine. We have sent a number of letters, emails, SMS to you and
                our Enforcement Agents have attended your property in an attempt
                to resolve this matter.
              </Paragraph>
              <Paragraph fontSize='16px'>
                We are required to recover the sums outstanding, or take control
                of your goods using forced entry if necessary if you continue to
                ignore our payment requests. If you cannot pay in full
                immediately we may be able to agree a payment arrangement to
                clear this debt.
              </Paragraph>
              <Paragraph fontSize='16px'>
                Please be aware that we may also refer this matter back to the
                court for consideration of issuing a Warrant for your arrest.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Heading1 color='#3d3d3d' marginTop='40px'>
            Financial Arrest Warrants
          </Heading1>

          <Accordion
            title='I have received notification that there is a warrant for my
                  arrest, what shall I do?'
            id='warrant-for-my-arrest'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                A Warrant for your immediate arrest has been issued by the
                criminal courts and sent to us for execution. The only way to
                avoid arrest is to pay the outstanding amount in full either{' '}
                <Link
                  href='https://www.jacobsenforcement.com/pay-now/'
                  aria-label='This is an external link (opens in a new tab)'
                >
                  online
                </Link>
                , via our automated{' '}
                <Link href='/pay-now' as='a' target='__blank' fontSize='16px'>
                  24 hour telephone payment line
                </Link>{' '}
                or by{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  {' '}
                  contacting us
                </Link>{' '}
                to make payment in full.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If you are unable to pay in full contact our Enforcement Team
                immediately to agree a date for you to attend court voluntarily.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If you do not pay or contact us an Arrest Officer will attend
                your property to arrest and transport you to court. Please note
                that an Arrest Officer may enforce this Warrant anywhere (e.g.
                public place, place of employment) at any time.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If you are arrested outside of court hours you may be detained
                overnight in police custody until the court is available to hear
                the matter.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title=' I cannot afford to pay in full immediately; can I agree to pay by
                  instalments?'
            id='cannot-afford'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                No, the Warrant requires you to be arrested if you fail to pay
                the amount owing in full. However, there are other ways this
                matter can be resolved:
              </Paragraph>
              <ul>
                <BulletPoint>
                  <strong>
                    You can pay 50% of the debt to us and you can then apply to
                    the court to pay the balance by instalments direct to them
                  </strong>
                </BulletPoint>
              </ul>
              <Paragraph fontSize='16px'>
                The 50% payment must be made before you make your application
                via us to be presented to the court. If you wish to do this
                please{' '}
                <Link href='/contact' as='a' target='__blank'>
                  contact us
                </Link>
                . Please note that if the court refuses the application a
                further Warrant for your arrest will be sent to us for immediate
                execution.
              </Paragraph>
              <Paragraph fontSize='16px' fontStyle='italic'>
                <strong>Note:</strong> This option is only available if you have
                not previously broken a payment agreement with the court
                regarding this Warrant and only if the Warrant is not a Breach
                Warrant, Warrant of Commitment or Committal to Custody Overnight
                in a Police Station.
              </Paragraph>
              <ul>
                <BulletPoint>
                  <strong>
                    If you are employed or in receipt of benefits you can make a
                    payment off the debt to us and you can then apply to the
                    court to pay the balance by instalments direct to them
                    through Deduction of Benefits or Attachment of Earnings
                  </strong>
                </BulletPoint>
              </ul>
              <Paragraph fontSize='16px'>
                The part payment must be made before you make your application
                via us to be presented to the court. If you wish to do this
                please{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  contact us
                </Link>
                . Please note that if the court refuses the application a
                further Warrant for your arrest will be sent to us for immediate
                execution.
              </Paragraph>
              <Paragraph fontSize='16px' fontStyle='italic'>
                <strong>Note:</strong> This option is only available if you have
                not previously broken a payment agreement with the court
                regarding this Warrant and only if the Warrant is not a Breach
                Warrant, Warrant of Commitment or Committal to Custody Overnight
                in a Police Station.
              </Paragraph>
              <ul>
                <BulletPoint>
                  <strong>
                    You can agree to voluntarily attend court on a fixed date –
                    Planned Surrender
                  </strong>
                </BulletPoint>
              </ul>
              <Paragraph fontSize='16px'>
                If you wish to do this please{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  contact us
                </Link>
                .
              </Paragraph>
              <Paragraph fontSize='16px' fontStyle='italic'>
                <strong>Note:</strong> If you fail to attend a planned surrender
                date a further Arrest Warrant will be issued for your immediate
                arrest and your non-attendance will be taken into consideration
                by the court when making any further orders.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='I cannot pay, how can I avoid being arrested?'
            id='cannot-pay'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                The Warrant requires you to be arrested if you fail to pay the
                amount owing in full. However there are other ways this matter
                can be resolved:
              </Paragraph>
              <ul>
                <BulletPoint>
                  You can pay 50% of the debt to us and you can then apply to
                  the court to pay the balance by instalments direct to them
                </BulletPoint>
              </ul>
              <Paragraph fontSize='16px'>
                The 50% payment must be made before you make your application
                via us to be presented to the court. If you wish to do this
                please{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  contact us
                </Link>
                . Please note that if the court refuses the application a
                further Warrant for your arrest will be sent to us for immediate
                execution.
              </Paragraph>
              <Paragraph fontSize='16px' fontStyle='italic'>
                <strong>Note:</strong> This option is only available if you have
                not previously broken a payment agreement with the court
                regarding this Warrant and only if the Warrant is not a Breach
                Warrant, Warrant of Commitment or Committal to Custody Overnight
                in a Police Station.
              </Paragraph>
              <ul>
                <BulletPoint>
                  If you are employed or in receipt of benefits you can make a
                  payment off the debt to us and you can then apply to the court
                  to pay the balance by instalments direct to them through
                  Deduction of Benefits or Attachment of Earnings
                </BulletPoint>
              </ul>
              <Paragraph fontSize='16px'>
                The part payment must be made before you make your application
                via us to be presented to the court. If you wish to do this
                please{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  contact us
                </Link>
                . Please note that if the court refuses the application a
                further Warrant for your arrest will be sent to us for immediate
                execution.
              </Paragraph>
              <Paragraph fontSize='16px' fontStyle='italic'>
                <strong>Note:</strong> This option is only available if you have
                not previously broken a payment agreement with the court
                regarding this Warrant and only if the Warrant is not a Breach
                Warrant, Warrant of Commitment or Committal to Custody Overnight
                in a Police Station.
              </Paragraph>
              <ul>
                <BulletPoint>
                  You can agree to voluntarily attend court on a fixed date –
                  Planned Surrender
                </BulletPoint>
              </ul>
              <Paragraph fontSize='16px'>
                If you cannot pay the amount on the Warrant in full you can
                still avoid arrest and transportation to Court by contacting the
                Arrest Officers or Enforcement Team to arrange to attend court
                yourself at a date and time to be agreed. If you wish to do this
                please{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  contact us
                </Link>
                .
              </Paragraph>
              <Paragraph fontSize='16px' fontStyle='italic'>
                <strong>Note:</strong> If you fail to attend a planned surrender
                date a further Arrest Warrant will be issued for your immediate
                arrest and your non-attendance will be taken into consideration
                by the court when making any further orders.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='I cannot attend the court date I agreed to attend, what shall I
              do?'
            id='cannot-attend'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                If you fail to attend the court date as agreed it may negatively
                influence the decision of the court when making any order
                against you. It is therefore an utmost priority that you attend.
                If you cannot attend due to unforeseen circumstances which
                cannot be avoided (e.g., illness, Covid-19 self-isolation) then
                please{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  contact us
                </Link>{' '}
                immediately.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='How can I stop being arrested where there is a commitment
                  warrant/committal to custody order issued?'
            id='stop-being-arrested'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                A Warrant for your immediate arrest has been issued by the
                criminal courts and sent to us for execution. The only way to
                avoid arrest is to pay the outstanding amount in full either{' '}
                <Link
                  href='https://www.jacobsenforcement.com/pay-now/'
                  aria-label='This is an external link (opens in a new tab)'
                >
                  online
                </Link>
                , via our automated{' '}
                <Link href='/pay-now' as='a' target='__blank' fontSize='16px'>
                  24 hour telephone payment line
                </Link>{' '}
                or by{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  {' '}
                  contacting us
                </Link>{' '}
                to make payment in full.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If your circumstances have changed since the Warrant was issued
                you can be taken back before the court for them to re-consider
                whether immediate imprisonment remains appropriate.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='Where can I be arrested?'
            id='where-can-i-be-arrested'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                You can be arrested anywhere in England and Wales. This can be
                are your home, place of employment, whilst visiting a friend, at
                your children’s school, in the local pub or whilst shopping etc.
              </Paragraph>
              <Paragraph fontSize='16px'>
                Arrest Officers will make every effort to be discreet and avoid
                sensitive locations for arrest however if you consistently
                refuse to engage with us they are required to arrest you on
                sight regardless of location.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='What does ‘circulated to the police’ mean?'
            id='circulated-to-the-police'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                Details of the Warrant have been added to the Police National
                Computer system. This means that the Police as well as Jacobs
                Arrest Officers have been requested to arrest you and bring you
                before the court.
              </Paragraph>
              <Paragraph fontSize='16px'>
                The Police can arrest and detain you during any routine checks
                or enquiries they may make including at airports when departing
                or arriving.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion title='When can I be arrested?' id='be-arrested2'>
            <FAQSection>
              <Paragraph fontSize='16px'>
                You can be arrested and detained when executing a Financial
                Arrest Warrant at any time. If you are arrested outside of court
                hours you may be held in police custody until a court is
                available to hear the matter.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion id='forced-entry2' title='What does Forced Entry mean?'>
            <FAQSection>
              <Paragraph fontSize='16px'>
                If you fail to engage with us to agree your attendance at court
                and actively avoid arrest, as Approved Enforcement Agents, we
                have the power to force entry and search property and/or
                premises under the Magistrates’ Courts Act 1980, Schedule 4a.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If we are required to use force to execute the Warrant this will
                be taken into consideration by the court when it considers any
                further orders it makes against you.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Heading1 color='#3d3d3d' marginTop='40px'>
            Community Penalty Breach Warrants
          </Heading1>

          <Accordion
            title='I do not know what this is about'
            id='i-do-not-know'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                This Warrant has been issued by HMCTS on behalf of the Probation
                Service or Young Offenders Service. Details of the court that
                issued the fine, the court reference and hearing date(s) are
                detailed on all of the letters Jacobs have sent to you.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If you believe that this Warrant has been issued incorrectly you
                must contact the Magistrates court directly to discuss this.
                Please note that we will continue to enforce the Warrant until
                such time the court withdraws the Warrant or you appear before
                the court.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion title='How can I avoid arrest?' id='how-can-i-avoid'>
            <FAQSection>
              <Paragraph fontSize='16px'>
                The Breach Warrant requires your attendance at court and has
                been circulated to the Police who may arrest you on sight or
                during any routine checks.
              </Paragraph>
              <Paragraph fontSize='16px'>
                To avoid immediate arrest, you must{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  contact us
                </Link>{' '}
                straight away. If you do so we can book a court, date and time
                for you to attend court voluntarily. Please be aware that if you
                fail to attend a planned surrender date a further Warrant will
                be issued for your immediate arrest and your non-attendance will
                be taken into consideration by the court when making any further
                orders against you.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='I cannot attend the court date I agreed, what should I do?'
            id='i-cannot-attend-the-court'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                If you fail to attend the court date as agreed it may negatively
                influence the decision of the court when making any order
                against you. It is therefore an utmost priority that you attend.
                If you cannot attend due to unforeseen circumstances which
                cannot be avoided (e.g. illness, Covid-19 self-isolation) then
                please{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  contact us
                </Link>{' '}
                immediately.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='Where can I be arrested?'
            id='where-can-i-be-arrested2'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                You can be arrested anywhere in England and Wales. This can be
                at your home, place of employment, whilst visiting a friend, at
                your children’s school, in the local pub or whilst shopping etc.
              </Paragraph>
              <Paragraph fontSize='16px'>
                Arrest Officers will make every effort to be discreet and avoid
                sensitive locations for arrest however if you consistently
                refuse to engage with us they are required to arrest you on
                sight regardless of location.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='What happens if I refuse to agree a date to attend court?'
            id='what-happens-if-i-refuse'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                An Arrest Officer will attend your address and any other
                location you are associated with for example place of
                employment, local gym to arrest and transport you to prison.
              </Paragraph>
              <Paragraph fontSize='16px'>
                Breach Warrants do not expire and both the Police and Jacobs
                Arrest Officers will continue to enforce the Warrant until your
                attendance at court is confirmed or the court withdraws the
                Warrant.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='What does ‘circulated to the police’ mean?'
            id='circulated-to-the-police2'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                Details of the Warrant have been added to the Police National
                Computer system. This means that the Police as well as Jacobs
                Arrest Officers have been requested to arrest you and bring you
                before the court.
              </Paragraph>
              <Paragraph fontSize='16px'>
                The Police can arrest and detain you during any routine checks
                or enquiries they may make including at airports when departing
                or arriving.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='When can I be arrested?'
            id='when-can-i-be-arrested'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                You can be arrested and detailed under a Breach Warrant at any
                time. If you are arrested outside of court hours you may be held
                in police custody until a court is available to hear the matter.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion id='forced-entry' title='What does Forced Entry mean?'>
            <FAQSection>
              <Paragraph fontSize='16px'>
                If you fail to engage with us to agree your attendance at court
                and actively avoid arrest, as Approved Enforcement Agents, we
                have the power to force entry and search property and/or
                premises under the Magistrates Courts Act 1980, Schedule 4a.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If we are required to use force to execute the Warrant this will
                be taken into consideration by the court when it considers any
                further orders it makes against you.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Heading1 color='#3d3d3d' marginTop='40px'>
            Commitment Warrants
          </Heading1>

          <Accordion
            title='What is a Commitment Warrant?'
            id='what-is-a-commitment-Warrant'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                The court has imposed a term of imprisonment at a court hearing
                in your absence and issued a Warrant to Jacobs Arrest Officers
                to execute by arresting you and transporting you to the Prison
                directed on the Warrant.
              </Paragraph>
              <Paragraph fontSize='16px'>
                You can avoid arrest and transportation to prison by immediately
                paying the outstanding amount in full either{' '}
                <Link
                  href='https://www.jacobsenforcement.com/pay-now/'
                  aria-label='This is an external link (opens in a new tab)'
                >
                  online
                </Link>
                , via our automated{' '}
                <Link href='/pay-now' as='a' target='__blank' fontSize='16px'>
                  24 hour telephone payment line
                </Link>{' '}
                or by{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  contacting us
                </Link>{' '}
                to make payment in full.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If you cannot pay in full you must contact the Arrest Officer
                and/or Enforcement Team to arrange for a voluntary surrender for
                us to meet you at home and execute the Warrant and transport you
                to prison.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If your circumstances have changed since the Warrant was issued
                you can be taken back before the court for them to re-consider
                whether immediate imprisonment remains appropriate. Please note
                that we will continue to execute the Warrant until we are
                instructed otherwise by the court.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='How can I stop being arrested where there is a Commitment Warrant
                  issued?'
            id='stop-being-arrested2'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                A Warrant for your immediate arrest has been issued by the
                criminal courts and sent to us for execution. The only way to
                avoid arrest is to pay the outstanding amount in full either{' '}
                <Link
                  href='https://www.jacobsenforcement.com/pay-now/'
                  aria-label='This is an external link (opens in a new tab)'
                >
                  online
                </Link>{' '}
                or by{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  contacting us
                </Link>{' '}
                to make payment in full.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If your circumstances have changed since the Warrant was issued
                you can be taken back before the court for them to re-consider
                whether immediate imprisonment remains appropriate.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion title='Where can I be arrested?' id='where-can-i-be'>
            <FAQSection>
              <Paragraph fontSize='16px'>
                You can be arrested anywhere in England and Wales. This can be
                at your home, place of employment, whilst visiting a friend, at
                your children’s school, in the local pub or whilst shopping etc.
              </Paragraph>
              <Paragraph fontSize='16px'>
                Arrest Officers will make every effort to be discreet and avoid
                sensitive locations for arrest however if you consistently
                refuse to engage with us they are required to arrest you on
                sight regardless of location.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='What does Forced Entry mean?'
            id='forced-entry-meaning'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                If you fail to engage with us and actively avoid arrest, as
                Approved Enforcement Agents, we have the power to force entry
                and search property and/or premises under the Magistrates Courts
                Act 1980, Schedule 4a.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='What does ‘circulated to the police’ mean?'
            id='circulated-to-the-police3'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                Details of the Commitment Warrant have been added to the Police
                National Computer system. This means that the Police as well as
                Jacobs Arrest Officers have been requested to arrest you and
                transport to prison.
              </Paragraph>
              <Paragraph fontSize='16px'>
                The Police can arrest and detain you during any routine checks
                or enquiries they may make including at airports when departing
                or arriving.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Heading1 color='#3d3d3d' marginTop='40px'>
            Committal to Custody Overnight Warrants
          </Heading1>

          <Accordion
            title='What is a Committal to Custody Overnight Warrant?'
            id='what-is-committal'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                The court has issued a Warrant for you to be arrested and
                transported to a police station and held in custody overnight.
              </Paragraph>
              <Paragraph fontSize='16px'>
                You can avoid arrest and transportation to police station
                custody by immediately paying the outstanding amount in full
                either{' '}
                <Link
                  href='https://www.jacobsenforcement.com/pay-now/'
                  aria-label='This is an external link (opens in a new tab)'
                >
                  online
                </Link>
                , via our automated{' '}
                <Link href='/pay-now' as='a' target='__blank' fontSize='16px'>
                  24 hour telephone payment line
                </Link>{' '}
                or by{' '}
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  {' '}
                  contacting us
                </Link>{' '}
                to make payment in full.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If you cannot pay in full you must contact the Arrest Officer
                and/or Enforcement Team to arrange for a voluntary surrender for
                us to meet you at home to execute the Warrant and transport you
                to police custody.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If your circumstances have changed since the Warrant was issued
                you can be taken back before the court for them to re-consider
                whether immediate custody overnight remains appropriate. Please
                note that we will continue to execute the Warrant until we are
                instructed otherwise by the court.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='How can I stop being arrested where there is a committal to
                  custody order issued?'
            id='committal-to-custody'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                A Warrant for your immediate arrest has been issued by the
                criminal courts and sent to us for execution. The only way to
                avoid arrest is to pay the outstanding amount in full either
                <Link
                  href='https://www.jacobsenforcement.com/pay-now/'
                  aria-label='This is an external link (opens in a new tab)'
                >
                  {' '}
                  online
                </Link>
                , via our automated{' '}
                <Link href='/pay-now' as='a' target='__blank' fontSize='16px'>
                  24 hour telephone payment line
                </Link>{' '}
                or by
                <Link href='/contact' as='a' target='__blank' fontSize='16px'>
                  {' '}
                  contacting us
                </Link>{' '}
                to make payment in full.
              </Paragraph>
              <Paragraph fontSize='16px'>
                If your circumstances have changed since the Warrant was issued
                you can be taken back before the court for them to re-consider
                whether immediate custody to a police station overnight remains
                appropriate.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='Where can I be arrested?'
            id='committal-where-can-i-be-arrested'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                You can be arrested anywhere in England and Wales. This can be
                are your home, place of employment, whilst visiting a friend, at
                your children’s school, in the local pub or whilst shopping etc.
              </Paragraph>
              <Paragraph fontSize='16px'>
                Arrest Officers will make every effort to be discreet and avoid
                sensitive locations for arrest however if you consistently
                refuse to engage with us they are required to arrest you on
                sight regardless of location.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='What does Forced Entry mean?'
            id='committal-forced-entry'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                If you fail to engage with us and actively avoid arrest, as
                Approved Enforcement Agents, we have the power to force entry
                and search property and/or premises under the Magistrates Courts
                Act 1980, Schedule 4a. In such circumstances we will apply to
                the court for authority to take such action and use locksmiths
                to enter a property where we believe you are present.
              </Paragraph>
            </FAQSection>
          </Accordion>

          <Accordion
            title='What does ‘circulated to the police’ mean?'
            id='committal-circulated'
          >
            <FAQSection>
              <Paragraph fontSize='16px'>
                Details of the Commitment Warrant have been added to the Police
                National Computer system. This means that the Police as well as
                Jacobs Arrest Officers have been requested to arrest you and
                transport to prison.
              </Paragraph>
              <Paragraph fontSize='16px'>
                The Police can arrest and detain you during any routine checks
                or enquiries they may make including at airports when departing
                or arriving.
              </Paragraph>
            </FAQSection>
          </Accordion>
        </div>
      </Flexbox>
      <ScrollToTop showBelow={800} />
    </Section>
  </>
);

FaqPage.propTypes = {
	theme: PropTypes.object,
};

FaqPage.defaultProps = {
	theme: defaultTheme,
};

export default FaqPage;
