import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import propTypes from '@styled-system/prop-types';
import Card from '../Card';
import { Heading1 } from '../Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons';

const AccordionContainer = styled.div(space);

const expandStyleFn = ({ expanded }) => {
	if (expanded) {
		return css`
			height: auto;
			opacity: 1;
			overflow: visible;
			margin-top: 16px;
			margin-bottom: 60px;
		`;
	}

	return css`
		height: 0;
		opacity: 0;
		overflow: hidden;
	`;
};

export const AccordionContent = styled.div`
	transition: 0.2s;
	margin-bottom: 16px;
	${expandStyleFn}
`;

export const AccordionCard = styled(Card).attrs((props) => ({
	...props,
	paddingBottom: '16px',
	paddingLeft: '16px',
	paddingRight: '50px',
	paddingTop: '16px',
	width: '100%',
}))`
	position: relative;
	cursor: pointer;

	user-select: none;
`;

const IconWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;

	height: 100%;
	padding: 0 16px;
	box-sizing: border-box;

	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;
`;

const Accordion = ({ children, defaultExpanded, title, id, ...otherProps }) => {
	const [expanded, setExpanded] = useState(defaultExpanded);
	const handleClick = useCallback(
		() => setExpanded((prevExpanded) => !prevExpanded),
		[]
	);

	return (
		<AccordionContainer {...otherProps}>
			<AccordionCard onClick={handleClick}>
				<Heading1
					lineHeight='22px'
					fontSize='16px'
					marginBottom='0px'
					id={id}
				>
					{title}
				</Heading1>

				<IconWrapper>
					{expanded ? (
						<FontAwesomeIcon
							icon={faMinusSquare}
							color='#27315f'
							size='md'
						/>
					) : (
						<FontAwesomeIcon
							icon={faPlusSquare}
							color='#27315f'
							size='md'
						/>
					)}
				</IconWrapper>
			</AccordionCard>
			<AccordionContent expanded={expanded}>{children}</AccordionContent>
		</AccordionContainer>
	);
};

Accordion.propTypes = {
	...propTypes.space,

	children: PropTypes.node.isRequired,
	defaultExpanded: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
};

Accordion.defaultProps = {
	defaultExpanded: false,
};

export default Accordion;
