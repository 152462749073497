import React, { useState } from 'react';
import styled from 'styled-components';
import FAQItem from './FAQItem';
import faqData from './faqData';
import Button from '../Button';
import Menu from '../../images/menu.svg';
import Close from '../../images/close.svg';

const Container = styled.div`
  background: #fff;
  padding: 12px;
  border-radius: 4px;
`;

const NavButton = styled(Button)`
  margin-bottom: 16px;
  img {
    margin-right: 8px;
    margin-bottom: 0;
  }
  span {
    display: flex;
  }
  .arrow {
    height: 22px;
    transition: transform 0.2s linear;
    user-select: none;
  }
  .rotate {
    transform: rotate(90deg);
  }
`;

function FaqMobileNav() {
  const item = faqData.map((data, index) => {
    return (
      <FAQItem data={data} defaultOpen={index === 0} key={data.faqHeading} />
    );
  });

  const [show, setShow] = useState(true);

  function toggle() {
    setShow((prevState) => !prevState);
  }

  return (
    <>
      <NavButton onClick={toggle}>
        {show ? <img src={Menu} alt='' /> : <img src={Close} alt='' />}
        {show ? 'Show' : 'Hide'} Contents
      </NavButton>
      {!show && <Container>{item}</Container>}
    </>
  );
}

export default FaqMobileNav;
