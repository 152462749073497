import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Arrow from '../../images/arrow-white.svg';

const IconButton = styled.div`
	position: fixed;
	right: 90px;
	display: flex;
	bottom: 28px;
	align-items: center;
	z-index: 1000;
	cursor: pointer;
	background: #27315f;
	color: white;
	height: 50px;
	border-radius: 50px;
	transition: 0.3s;
	justify-content: center;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 1.3px;
	padding: 11px 16px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	:hover {
		background: #1e2269;
	}
	img {
		transform: rotate(-90deg);
		margin-top: 18px;
	}
	@media (max-width: 980px) {
		right: 40px;
		justify-content: center;
	}
`;

const ScrollToTop = ({ showBelow }) => {
	const [show, setShow] = useState(showBelow ? false : true);

	const handleScroll = () => {
		if (window.pageYOffset > showBelow) {
			setShow(true);
		} else {
			setShow(false);
		}
	};

	const handleClick = () => {
		window.scrollTo({ top: 0, behavior: `smooth` });
	};

	useEffect(() => {
		if (showBelow) {
			window.addEventListener(`scroll`, handleScroll);
			return () => window.removeEventListener(`scroll`, handleScroll);
		}
	});

	return (
		<div>
			{show && (
				<IconButton
					onClick={handleClick}
					aria-label='to top'
					component='span'
				>
					Scroll to top
					<img src={Arrow} alt='arrow icon' />
				</IconButton>
			)}
		</div>
	);
};
export default ScrollToTop;
