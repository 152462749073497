import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const TableContainer = styled.table`

    font-family: Proxima Nova;
    border-collapse: collapse;
    margin: 12px 0 24px 0;
    font-size: 16px;
    height: 40px;
    vertical-align: middle;
    font-variant-numeric: tabular-nums;
    width: 100%;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`

    border: 1px solid #b2bad37c;
`;

const cssFn = ({ css }) => css;

export const TableHeaderCell = styled.th`

    font-weight: 700;
    text-align: left;
    color: #323240;
    background-color: #eef1f8;
    padding: 8px;
    white-space: nowrap;
    width: auto;

    ${cssFn}
`;

export const TableDataCell = styled.td`

    border-collapse: collapse;
    margin: 12px 0 24px 0;
    font-size: 16px;
    height: 40px;
    vertical-align: middle;
    font-variant-numeric: tabular-nums;
    font-weight: 500;
    padding: 10px 8px 10px 8px;
    background-color: #fff;
    color: #444;
    width: auto;

    ${cssFn}

    >li{
        font-size: .75rem;
        margin-top:0px;
        margin-left: 8px;
        margin-bottom:0px;
    }
`;

const Table = ({
    DataCellComponent: DataCell,
    header,
    HeaderCellComponent: HeaderCell,
    rows,
    RowComponent: Row,
    ...otherProps
}) => {

    const hasHeader = !!header.length;

    return (
        <TableContainer
            {...otherProps}
        >

            <TableBody>
                {hasHeader && (
                    <Row>
                        {header.map(({ key, content, ...otherCellProps }, i) => (
                            <HeaderCell
                                key={`header-${key || i}`}
                                {...otherCellProps}
                            >
                                {content}
                            </HeaderCell>
                        ))}
                    </Row>
                )}

                {rows.map((row, i) => !!row.length && (
                    <Row
                        key={`data-row-${i}`}
                    >
                        {row.map(({ key, content, ...otherCellProps }, i) => (
                            <DataCell
                                key={`data-${key || i}`}
                                {...otherCellProps}
                            >
                                {content}
                            </DataCell>
                        ))}
                    </Row>
                ))}
            </TableBody>

        </TableContainer>
    );
};

Table.propTypes = {
    DataCellComponent: PropTypes.elementType.isRequired,
    header: PropTypes.array.isRequired,
    HeaderCellComponent: PropTypes.elementType.isRequired,
    rows: PropTypes.array.isRequired,
    RowComponent: PropTypes.elementType.isRequired,
};

Table.defaultProps = {
    DataCellComponent: TableDataCell,
    header: [],
    HeaderCellComponent: TableHeaderCell,
    rows: [],
    RowComponent: TableRow
};

export default Table;
