import styled from 'styled-components';
import { compose, fontSize } from 'styled-system';
import propTypes from '@styled-system/prop-types';

const paragraphStyle = compose(fontSize);

const BulletPoint = styled.li`
  list-style: square;
  list-style-position: inside;
  ${paragraphStyle}
`;

BulletPoint.propTypes = {
  ...propTypes.border,
  ...propTypes.color,
  ...propTypes.fontSize,
  ...propTypes.fontStyle,
  ...propTypes.fontWeight,
  ...propTypes.lineHeight,
  ...propTypes.letterSpacing,
  ...propTypes.space,
};

BulletPoint.defaultProps = {
  color: 'text-grey',
  fontSize: '15px',
};

export default BulletPoint;
