import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Arrow from '../../images/arrow.svg';

const FAQItem = ({ data, defaultOpen }) => {
  const liItem = data.headings.map((item) => (
    <li key={item.title}>
      <Link href={item.url}>{item.title}</Link>
    </li>
  ));

  const [show, setShow] = useState(defaultOpen);

  function toggle() {
    setShow((prevState) => !prevState);
  }

  return (
    <>
      <NavGroup onClick={toggle}>
        <Heading>
          <Link href={data.headingUrl}>{data.faqHeading}</Link>
        </Heading>
        <div className={`arrow ${show ? 'rotate' : ''}`}>
          <img src={Arrow} alt='' />
        </div>
      </NavGroup>
      {show && (
        <Container>
          <ul>{liItem}</ul>
        </Container>
      )}
    </>
  );
};

export default FAQItem;

const NavGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;
  img {
    margin: 0px;
  }
  .arrow {
    height: 24px;
    transition: transform 0.2s linear;
    user-select: none;
  }
  .rotate {
    transform: rotate(90deg);
  }
`;

const Heading = styled.h2`
  font-size: 17px;
  margin: 0px;
  max-width: 290px;
  line-height: 24px;
  a {
    color: #3d3d3d;
    text-decoration: none;
  }
  a:hover {
    color: black;
  }
`;

const Container = styled.ul`
  margin-left: 16px;
  list-style: none;
  font-size: 15px;
  padding-left: 16px;
  border-left: 1px solid #eee;
  line-height: 19px;
  li {
    list-style: none;
  }
  a {
    color: #4f4f4f;
    text-decoration: none;
  }
  ,
  a:hover {
    color: black;
  }
`;
