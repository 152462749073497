import React from 'react';
import FAQItem from './FAQItem';
import faqData from './faqData';
import styled from 'styled-components';

const Container = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 4px;
`;

function faqSidebar() {
  const item = faqData.map((data, index) => {
    return (
      <Container>
        <FAQItem data={data} defaultOpen={index === 0} key={data.faqHeading} />
      </Container>
    );
  });
  return <>{item}</>;
}

export default faqSidebar;
